import { gsap } from "gsap";
import $ from "jquery";
window.$ = window.jQuery = $;
import SmoothScroll from "./vendors/smooth-scroll/smooth-scroll.polyfills";

import Swiper from "swiper/bundle";
// var scroll = new SmoothScroll('a[href*="#"]', {
//   updateURL: false,
//   duration : 20,
//   easing : 'linear'
// });

(function ($) {
  "use strict";
  window.$wind = jQuery(window);
  window.$html = jQuery("html");
  var scrDown = 0;


  
  menuInit();
  featureCardInit();
  newsEventsSlider();
  aboutUsSlider();
  highlightsSlider();
  // playButton();
  // openSearch();



  $(document).ready(function(){
    setHeight();
    $('.pause-video').click(function(){
      document.querySelector('#bgvid').pause();
      $(this).addClass('d-none');
      $('.play-video').removeClass('d-none');
    });

    $('.play-video').click(function(){
      document.querySelector('#bgvid').play();
      $(this).addClass('d-none');
      $('.pause-video').removeClass('d-none');
    })
  });


  window.addEventListener('resize', setHeight);


  function setHeight(){
    if($('.section-peoples-list .image-holder').length > 0){

      let width = $('.section-peoples-list .image-holder').width();
      $('.section-peoples-list .image-holder').height(width);
    }
  }
  function printPage(){
    window.print();
  }
  function menuInit() {
    const menu = document.getElementById("site-menu-header");
    const searchToggleElm = document.querySelector(".search-toggle");
    if (!menu) return;

    if (!menu.classList.contains("inner-page-header")) {
      $(window).scroll(function () {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition >= 170) {
          menu.classList.add("nav-bg", "hide-nav");
        } else {
          menu.classList.remove("nav-bg", "hide-nav");
        }
      });
    }

    const burger = document.querySelector(".menu-toggle");
    const tl = gsap.timeline({
      onComplete: () => {
        $html.removeClass("over-hidden");
      },
    });
    const tl_search = gsap.timeline({
      onComplete: () => {
        $html.removeClass("over-hidden");
      },
    });

    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    // Execute code if screen width is greater than 767px
    if (screenWidth > 767) {
      // Your JavaScript code to execute here
      tl.to(".menu-link", {
        yPercent: 100,
        duration: 0.5,
      });
      // console.log("Screen width is greater than 767 pixels.");
    }

  
    if(jQuery('html').attr('lang') == 'ar' ){
      tl.to(".menu-overlay", {
        width: 0,
        left: 0,
      }).progress(1);
    }
    else{
      tl.to(".menu-overlay", {
        width: 0,
        right: 0,
      }).progress(1);
    }
   
    tl_search.to(".search-overlay", { y: "-100%", duration: 1, ease: "power4.in" }, "top").progress(1);

    gsap.set(".search-overlay", { opacity: 1 });

    searchToggleElm.addEventListener("click", (e) => {
      tl_search.reversed(!tl_search.reversed());
      $html.addClass("over-hidden");
 
      $(".search-overlay").toggleClass("search-overlay-open");  
      if($(".search-overlay").hasClass('search-overlay-open')){
        // $('body').removeClass('disable-animation');
        // $('main').css('height','auto');
        setTimeout(function() {
          var maxScroll = $('.search-overlay-open').outerHeight();
          $('main').css('height',maxScroll  );
        }, 2000);
       
       
        $('body').addClass('disable-animation');

      }
      else{
        var maxScroll = $('.search-overlay-open').outerHeight();
        // $('main').css('height',maxScroll  );
        $('main').css('height','100%');
        $('main').css('overflow-y','auto');
         $('body').addClass('disable-animation');

      }

      burger.classList.toggle("open");

      setTimeout(() => {
        $(".search-overlay .search-input").focus();
      }, 0);
    });

    gsap.set(".menu-overlay", { opacity: 1 });
    burger.addEventListener("click", (e) => {


      if ($(".search-overlay").hasClass("search-overlay-open")) {
        // tl_search.reversed(!tl_search.reversed());
      
        document.getElementById('search-overlay').click();
        // tl_search.reversed(!tl_search.reversed());

        // $(".search-overlay").toggleClass("search-overlay-open");
      } else {
        tl.reversed(!tl.reversed());
        $html.addClass("over-hidden");
        $(".menu-overlay").toggleClass("menu-overlay-open");
        $(".social-search-container").toggleClass("visibility");
        $('body').toggleClass('disable-animation');


        $('main').css('height','auto');

        e.currentTarget.classList.toggle("open");
        setTimeout(() => {
          $(".menu-list a").first().focus();
        }, 500);
      }
  
    });

    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('search-text')) {
    

      tl_search.reversed(!tl_search.reversed());
      $html.addClass("over-hidden");
      $(".search-overlay").toggleClass("search-overlay-open");
      
      setTimeout(function() {
        var maxScroll = $('.search-overlay-open').outerHeight();
        $('main').css('height',maxScroll );
      }, 2000);
     
      burger.classList.toggle("open");

    }

  }

 

  function featureCardInit() {
    var swiper = new Swiper(".feature-cards-row", {
      slidesPerView: 1,
      spaceBetween: 10,
      a11y: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        390: {
          slidesPerView: 1.5,
          spaceBetween: 20,
          centeredSlides: true,
        },
        430: {
          slidesPerView: 1.5,
          spaceBetween: 20,
          centeredSlides: true,
        },
        768: {
          slidesPerView: 2.1,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });

    gsap.utils.toArray(".serve--card").forEach((card) => {
      let q = gsap.utils.selector(card);

      gsap.set(q(".card-info-wrapper"), {
        yPercent: 87,
        y: 0,
      });

      let animation = gsap.timeline();

      card.addEventListener("mouseover", () => {
        // tl.play()
        animation.kill();
        animation = gsap
          .timeline()
          .to(q(".card-info-wrapper"), {
            duration: 0.3,
            yPercent: 0,
            opacity: 1,
          })
          .to(q(".card-info"), { duration: 0.1, top: "50%", yPercent: -50 }, "-=.3")
          .to(q(".card-desc"), { duration: 0.3, opacity: 1 }, "-=.15");
      });
      card.addEventListener("mouseleave", () => {
        // tlr.play()
        animation.kill();
        animation = gsap
          .timeline()
          .to(q(".card-desc"), { duration: 0.1, opacity: 0 }, ">-=.1")
          .to(q(".card-info-wrapper"), {
            duration: 0.3,
            yPercent: 87,
            opacity: 1,
          })
          .set(q(".card-info"), { top: 0, yPercent: 0 }, ">-=.3");
      });
    });
  }

  function aboutUsSlider() {
    /** fade slider */
    if ($(".about-us-slider").length > 0) {
      var totalSlide = $(".image-slider .swiper-slide").length;
      var currentElm = document.querySelector(".image-slider__current");
      var totalElm = document.querySelector(".image-slider__total");
      totalElm.innerHTML = totalSlide;
      const swiper = new Swiper(".swiper", {
        // Optional parameters
        direction: "horizontal",
        loop: true,
        effect: "fade",
        autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
      swiper.on("slideChange", function () {
        var current = swiper.realIndex + 1;
        if (current > totalSlide) current = 1;

        currentElm.innerHTML = current;
        totalElm.innerHTML = totalSlide;
      });
    }
  }

  function highlightsSlider() {
    /** highlingts slider  */
    const highlightsSlider = new Swiper(".highlights-swiper", {
      loop: false,
      slidesPerView: "auto",
      speed: 1200,
      freeMode: false,
      autoplay: true,
      pagination: {
        el: ".swiper-highlights-pagination",
        clickable: true,
      },
    });

    highlightsSlider.on("slideChange", function (swiper) {
      $(".highlights-item").removeClass("active").hide().fadeOut();
      $($(".highlights-item")[swiper.activeIndex]).addClass("active").hide().fadeIn();
    });
  }



  function newsEventsSlider() {
    var desktop_swiper = new Swiper(".desktop.news-events-slider", {
      speed: 1500,
      slidesPerView: 1.55,
      loop: true,
      slideToClickedSlide: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-btn-next",
        prevEl: ".swiper-btn-prev",
      },

      breakpoints: {
        900: {
          slidesPerView: 1,
        },
        1100: {
          slidesPerView: 2.1,
        },
        1500: {
          slidesPerView: 2.1,
        },
        2100: {
          slidesPerView: 2.1,
        },
      },
      on: {
        setTransition: function (swiper, speed) {
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + "ms";
            swiper.slides[i].querySelector(".slide-inner").style.transition = speed + "ms";
          }
        },
      },
    });

    desktop_swiper.on("slideChange", function (swiper) {
      console.log(swiper.realIndex);
      $(".slide-content-item").removeClass("active").hide().fadeOut();
      $($(".slide-content-item")[swiper.realIndex]).addClass("active").hide().fadeIn();
    });

    // $(".news-events-slider").each(function () {
    //   var sliderWidth = $(this).width();
    //   var sliderOffset = -(sliderWidth * 29) / 100;
    //   desktop_swiper.params.slidesOffsetBefore = sliderOffset;
    //   desktop_swiper.update();
    // });

    var mobile_swiper = new Swiper(".mobile.news-events-slider", {
      speed: 800,
      slidesPerView: 1,
      centeredSlides: true,
      loop: false,
      grabCursor: true,
      spaceBetween: 25,
      autoHeight: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-btn-next",
        prevEl: ".swiper-btn-prev",
      },
    });



 

  
  }

  $('body').on( "mouseover", function(e) {
      if(e.target.classList.contains('swiper-slide-active') ){
       $('.slide-content-list').addClass('mosueevent-on');
     }
       else if(!e.target.classList.contains('green-bg') ){
       $('.slide-content-list').removeClass('mosueevent-on');
      }
 })

  $('.video-item').click((e) => {
    let video_id = e.currentTarget.dataset.id;
    $('.video-player .opener').attr("src", video_id );
  })


  $(document).ready(function(){

      $('.scroll-top').click((e) => {
        e.preventDefault();
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Optional: adds a smooth scrolling animation
        });
      })

      var header_height = $('header').outerHeight();

      $('.hero').css('margin-top',-1 * header_height);
      $('#registration-page').css('margin-top', (-1 * header_height) - 80);
     
      if(header_height > 180 ){
        header_height = 160;
      }
      $('.menu-content').css('margin-top', (header_height) );

      $('.video-container').click((e) => {
          if($('.playback-control').hasClass('play')){
            $('.video-container').find('video')[0].play();
            $('.playback-control').removeClass('play');
            $('.playback-control').addClass('pause');
            $('.video-container').find('video').attr("controls", "controls");


          }
          else{
            $('.video-container').find('video')[0].pause();
            $('.playback-control').removeClass('pause')
            $('.playback-control').addClass('play');
            $('.video-container').find('video').removeAttr("controls");

          }
      })

      $('.categories-eligibilities li .hover-section ').click(function(){
        $(this).toggleClass('active');
      })

      var wpcf7Elm1 = document.querySelector( '#registration-page .wpcf7' );
 
      if(wpcf7Elm1){
        wpcf7Elm1.addEventListener( 'wpcf7submit', function( event ) {
          document.querySelector('.wpcf7').classList.add('invisible');
          document.querySelector('.thankyou-message').classList.add('d-flex');

      }, false );
      }
  

      var wpcf7Elm2 = document.querySelector( '#partner-with-page .wpcf7' );
 
      if(wpcf7Elm2){
        wpcf7Elm2.addEventListener( 'wpcf7submit', function( event ) {
          console.log( event );
          document.querySelector('.wpcf7').classList.add('invisible');
          document.querySelector('.thankyou-message').classList.add('d-flex');
  
      }, false );
      }
    
  })


})(jQuery);
